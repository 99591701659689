.tab-bar {
  display: inline-block;
  height: 64px;
  margin: -16px 0 0 32px;
}

.tab-bar a {
  color: #fff;
  display: inline-block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400; 
  height: 64px;
  line-height: 64px;
  padding: 0 24px;
  text-decoration:  none;
}

.tab-bar a:hover {
  color: #fff;
  background: #004d9b;
}

.tab-bar a:hover span {
  border-color: #f5f5f5;
}

.tab-bar a.active,
.tab-bar a.details{
  background: #0057B4;
  color: #fff
}
