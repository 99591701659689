:root {
  --site-blue: #3071A9;
  --site-amber-800: #FF8F00;
  --site-dark-blue: #174EA6;
  --site-status-green: #43A047;
  --site-green-100: #C8E6C9;
  --site-green-700:#388E3C;
  --site-grey-700: #5f6368;
  --site-grey-600: #80868B;
  --site-grey-300: #DADCE0;
  --site-off-white: #F1F3F4;
  --site-red: #EA4335;
  --site-dark-red: #D32F2F;
  --site-yellow-400:#FFCA28;
  --site-purple-800:#6A1B9A;
}
/* --- reset css --- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td{margin:0;padding:0;}
table{border-collapse:collapse;border-spacing:0;}
fieldset,img{border:0;}
address,caption,cite,code,dfn,em,th,var{font-style:normal;font-weight:normal;}
ol,ul {list-style:none;}
caption,th {text-align:left;}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;letter-spacing:0;margin:0;padding:0;}
q:before,q:after{content:'';}
abbr,acronym {border:0;}
select, input, textarea {font:99% arial,helvetica,clean,sans-serif;}
pre, code {font:115% monospace;}
a,a:focus,div:focus,ul:focus,button:focus {outline:0px;}
button::-moz-focus-inner {border: 0;}
body {background:#EDF0F3 !important; font-size:62.5%;font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;}
html {overflow-y:scroll;overflow-x:auto;}

#root {padding: 0 0 1px}

.btn {
  background: #fff;
  border: 1px solid #fff;
  border-radius:4px;
  color: #000;
  cursor: pointer;
  display:inline-block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight:400;
  line-height: 36px;
  margin: 0 10px 0 0;
  padding: 0 16px;
  text-align:center;
  text-decoration:none;
  -webkit-transition: background 0.8 ease-in-out;
  transition: background 0.8 ease-in-out;
}

.btn.outlined { border:1px solid rgba(0,0,0,.12); }

.btn:hover { background: #f5f5f5; }

.btn.outlined:hover {
  background: transparent;
  box-shadow: 0 1px 2px rgba(0,0,0,0.25);
}

.btn.green { color:#388e3c; }

.btn.outlined.green { border-color: #388e3c; }

.btn.filled.green { background: #388e3c; border-color: #388e3c;  color:#fff;}

.btn.green:hover { background: #e8f5e9; }

.btn.filled.green:hover { background: #2e7d32; border-color: #2e7d32;  color:#fff;}

.btn.blue { color:#1976d2; }

.btn.outlined.blue { border-color: #1976d2; }

.btn.filled.blue { background: #1976d2; border-color: #1976d2;  color:#fff;}

.btn.blue:hover { background:#e3f2fd; }

.btn.filled.blue:hover { background: #1565c0; border-color: #1565c0;  color:#fff;}

.btn.purple { color:#7b1fa2; }

.btn.outlined.purple { border-color: #7b1fa2; }

.btn.filled.purple { background: #7b1fa2; border-color: #7b1fa2;  color:#fff;}

.btn.purple:hover { background:#f3e5f5; }

.btn.filled.purple:hover { background: #6a1b9a; border-color: #6a1b9a;  color:#fff;}

.btn.red { color:#d32f2f; }

.btn.outlined.red { border-color: #d32f2f; }

.btn.filled.red { background: #d32f2f; border-color: #d32f2f;  color:#fff;}

.btn.red:hover { background:#ffebee; }

.btn.filled.red:hover { background: #c62828; border-color: #c62828;  color:#fff;}

.btn.yellow { color:#ffa500; }

.btn.outlined.yellow { border-color: #ffa500; }

.btn.yellow:hover { background: #fffde7; }

.btn:active,
.btn.outlined:active { background: #fff; box-shadow: inset 0 0 3px rgba(0,0,0,0.25)}

.btn.right {float: right; margin-right: 0;}

span.request-status {
  background: #efefef;
  border-radius: 2px;
  color: #616161;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 10px;
  padding: 5px 6px 4px;
  position: relative;
  text-transform: uppercase;
  top: -2px;
  white-space: nowrap;
}

span.request-status.blue { background: #e3f2fd; color: #1976d2; }

span.request-status.green { background: #e8f5e9; color: #388e3c; }

span.request-status.orange { background: #fff8e1; color: #f57f17; }

span.request-status.purple { background: #f3e5f5; color: #7b1fa2; }

span.request-status.red { background: #ffebee; color: #d32f2f; }

span.request-status.orange { background:#FFF3E0; color: #F57C00; }

span.request-status.black { background:#000; color: #fff; }

span.request-status.white {
  background:#fff;
  border:1px solid #000; 
  color:#000;
  padding: 4px 5px 3px;
}

.bread-crumbs {
  background: #fff;
  font-size: 14px;
  padding: 16px 16px 8px;
}

.bread-crumbs a:hover {
  text-decoration: underline;
}

.bread-crumbs span,
.bread-crumbs i {
  display: inline-block;
  vertical-align: top;
}

.bread-crumbs i {
  color: #1890ff;
}

.card {
  background: #fff;
  border-radius: 2px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;              
  margin: 16px;
  padding: 16px 16px 0;
}

.object-header {
  background: #fff;
  border-bottom: 1px solid #EDF0F3;
  padding: 0 16px 16px;
  position:  sticky;
  top: 0;
  z-index: 4;
}

.object-header h3 {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 6px;
  position: relative;
}

.object-header .object-meta span.id {
  color:#7e7e7e;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display:inline-block;
  margin: 0 8px 0 0px;
}

.header-actions {
  float:right;
  margin-top: -8px;
}

.header-versions {
  float:right;
  margin: -8px 8px 0 0;
}

.card h3 {
  border-bottom: 1px solid #ddd;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight:500;
  margin: -16px -16px 0;
  padding: 16px;
  position: relative;
}

.card h3.sticky {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 4;
}

.card h3 span.ant-dropdown-trigger {
  border:1px solid rgba(0,0,0,.12);
  border-radius:4px;
  color: #000;
  cursor: pointer;  
  display: block;
  float: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  padding: 0 16px;
}

.card h3 span.ant-dropdown-trigger:active {
  box-shadow: inset 0 0 3px rgba(0,0,0,0.25);
}

.card h3 span.ant-dropdown-trigger i {
  font-size: 18px;
  position: relative;
  top: 4px;
  transition: transform 0.15s linear;
}

.card h3 span.ant-dropdown-trigger.ant-dropdown-open i {
  transform: rotate(180deg);
}

.card h3.flat {
  margin-bottom: 0;
}

.mat-request-open {
  float: right;
}

.mat-req-assignments {
  float: right;
  margin-top: -4px;
}

.card .card-body {
  padding: 16px 0;
}

.card h5 {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight:500;
  margin: 0;
  padding: 0 0 16px;
  position: relative;
}

.card h5 a.rfq-id {
  font-size: 13px;
  font-weight: 400;
}

.card h5 a.rfq-id:hover {
  text-decoration: underline;
}

.card .card-body h5.right {
  text-align: right;
}

.card h5 span.count {
  background: #DDF3FF;
  border-radius: 50%;
  color: #5194E2;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  margin-left: 8px;
  padding: 2px 8px;
  position: relative;
  text-align: center;
  top: -1px;
}

.list-reset {
  float: right;
  margin: -5px 0 0 8px;
}

#search-box {
  float: right;
  margin: -5px 0 0 8px;
  position: relative;
}
#search-box:focus{
  border-bottom: 1px solid #3071A9;
}
#search-box label { float:right; }
#search-box i {
  color:#5f6368;
  position: absolute;
  right: 4px;
  top: 4px;
}
#search-box input {
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 32px;
  outline: 0;
  padding: 4px 32px 4px 8px;
  width: 250px;
}

#search-box input:focus{
  border: 1px solid #3071A9;
}

div.table-display-message {
  font-weight: bold;
  padding: 0 0 16px;
}

table.list,
table.mass-addition-table {
  width: 100%;
}

table.list th,
table.mass-addition-table th {
  background: #fafafa;
  border-bottom: 1px solid rgba(0,0,0,.06);  
  padding: 0;
  text-align: left;
}

table.list th button {
  background: #fafafa;
  border: 0;  
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  overflow-wrap: break-word;
  padding: 16px;
  position: relative;
  text-align: left;
  width: 100%;  
}

table.mass-addition-table th span,
table.list th span{
  background: #fafafa;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  position: relative;
  text-align: left;
  white-space: nowrap;  
  width: 100%; 
}

table.list th span {
  padding: 16px;
}

table.mass-addition-table th span em {
  color: #ff4d4f;
  padding-right: 4px;
}

table.list th span.centered {
  text-align: center;
}

table.list th span.right {
  text-align: right;
}

table.list th:not(:last-child) button:before,
table.mass-addition-table th:not(:last-child) span:before{
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: #0000000f;
    transform: translateY(-50%);
    transition: background-color .3s;
    content: "";
}

table.list th button .indicators {
  position: absolute;
  right: 24px;
  top: calc(50% - 10px);
}

table.list th button .indicators i {
  color: #a5a5a5;
  font-size: 16px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

table.list th button .indicators i + i {
  top: 6px
}

table.list th button.active.asc .indicators i:nth-of-type(1) {color: #1a8ffe}
table.list th button.active.desc .indicators i:nth-of-type(2) {color: #1a8ffe}

table.list td,
table.mass-addition-table td {
  border-bottom: 1px solid rgba(0,0,0,.06);  
  color: #000;
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: break-word;
  padding: 16px;
  position: relative;
  text-align: left;
  transition: background .3s ease;  
}

table.list tr.rfq-row td {
  font-weight: 500;
}

table.list td.right {
  text-align: right;
}

table.list td.doc-id {
  white-space: nowrap;
}

table.mass-addition-table td {
  padding: 0;
}

table.list td span.request-status {
  top: -1px;
}

table.list td a:hover {
  text-decoration: underline;
}

table.list td em.badge {
    background: #efefef;
    border-radius: 2px;
    color: #616161;
    font-family: 'Google Sans', Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 10px;
    margin-right: 8px;
    padding: 5px 6px 4px;
    position: relative;
    text-transform: uppercase;
    top: -1px;
    white-space: nowrap;
}

table.list td em.badge.rfq {
  background: #e3f2fd;
  color: #1976d2;
}

table.list td em.badge.quote {
  background: #f3e5f5;
  color: #7b1fa2;
}

/*--- pagination ---*/
.pagination {
  text-align: right;
}

.pagination button {
  background: #fff;
  border: 1px solid var(--site-grey-300);
  border-radius: 2px;
  color: var(--site-grey-700);
  cursor: pointer;
  display: inline-block;
  margin: 15px 0 0 8px;
  padding :0 5px;
  text-align: center;
  text-decoration: none;
}

.pagination button i {
  line-height:36px;
}

.pagination button:hover {
  color:#202124;
  box-shadow: 0 1px 2px rgba(0,0,0,0.25)
}

.pagination button:active {
  box-shadow: inset 0 0 3px rgba(0,0,0,0.25);
}

.pagination button.disabled,
.pagination button.disabled:hover,
.pagination button.disabled:active {
  opacity: 0.2;
  box-shadow: 0;
  cursor: default;
  pointer-events: none;
}

.split-form {
  display: flex;
}

.split-form-section {
  border-left: 1px solid #ddd;
  padding: 0 16px;
  width: 50%;
}

.split-form-section.right {
  padding-right: 0px;
}

.split-form-section.twenty-five {
  width: 25%;
}

.split-form-section.seventy-five {
  width: 75%;
}

.split-form-section:nth-of-type(1){
  border: 0;
  padding-left: 0;
}

.split-form-section.borderless {
  border: 0;
}

.form-section-header {
  border-bottom: 1px solid #eee;
  display: block;
  font-weight: bold;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.center-button {
  padding-top: 32px;
  text-align: center;
}

.center-button .ant-row {
  display:inline-block;
  margin-left: 8px;
}

span.no-values {
  color: #bfbfbf;
  display: block;
  height: 32px;
  line-height: 32px;
}

ul.timeline {
  box-sizing: border-box;
  color: #5f6368;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight:  400;
  letter-spacing:  0.28px;
  list-style: none;
  margin: 16px 0 0;
  padding: 8px 0 0;
}

ul.timeline li.timeline-item {
  list-style: none;  
  margin: 0;
  padding-bottom: 10px;
  position: relative;    
}

ul.timeline.right { text-align: right; }

ul.timeline li.timeline-item .timeline-item-tail {
  border-left: 2px solid #f0f0f0;
  height: calc(100% - 10px);
  left: 4px;  
  position: absolute;
  top: 10px;    
}

ul.timeline.right li.timeline-item .timeline-item-tail {
  left: auto;
  right: 4px;
}

ul.timeline li.timeline-item:last-child { padding-bottom: 0; }

ul.timeline li.timeline-item:last-child .timeline-item-tail { display: none; }

ul.timeline li.timeline-item .timeline-item-dot {
  background-color: #fff;
  border: 2px solid #bfbfbf;
  border-radius: 50%;
  box-sizing: border-box;
  height: 10px;  
  position: absolute;
  width: 10px;
}

ul.timeline.right li.timeline-item .timeline-item-dot { right: 0; }

ul.timeline li.timeline-item .timeline-item-icon {
  background-color: #fff; 
  height: 16px;
  position: absolute;
  left: -3px;
  top: -4px;
  width: 16px;
}

ul.timeline.right li.timeline-item .timeline-item-icon {
  left: auto;
  right: -3px;
}

ul.timeline li.timeline-item .timeline-item-icon i {
  color: #bfbfbf;
  font-size: 16px;
  height: 16px; 
  width: 16px;
}

ul.timeline li.timeline-item .timeline-item-icon.blue i { color: #4285f4; }

ul.timeline li.timeline-item .timeline-item-icon.green i { color: #34a853; }

ul.timeline li.timeline-item .timeline-item-icon.red i { color: #ea4335; }

ul.timeline li.timeline-item .timeline-item-icon.yellow i { color: #fbbc04; }

ul.timeline li.timeline-item .timeline-item-icon.purple i { color: #7b1fa2; }

ul.timeline li.timeline-item .timeline-item-dot.blue { border-color: #4285f4; }

ul.timeline li.timeline-item .timeline-item-dot.green { border-color: #34a853; }

ul.timeline li.timeline-item .timeline-item-dot.red { border-color: #ea4335; }

ul.timeline li.timeline-item .timeline-item-dot.yellow { border-color: #fbbc04; }

ul.timeline li.timeline-item .timeline-item-dot.purple { border-color: #7b1fa2; }

ul.timeline li.timeline-item .timeline-item-dot.orange {border-color: #F57C00}

ul.timeline li.timeline-item .timeline-item-dot.black {border-color: #000}

ul.timeline li.timeline-item .timeline-item-dot.white {
  background: #fff;
  border-color: #000;
}

ul.timeline li.timeline-item .timeline-item-content {
  line-height: 20px;
  margin: 0 0 0 26px;
  position: relative;
  top: -5.5px;  
  word-break: break-word;
}

ul.timeline.right li.timeline-item .timeline-item-content { 
  margin-left: 0;
  margin-right: 26px;
}

ul.timeline li.timeline-item .timeline-item-content p {
  margin: 0 0 8px;
}

ul.timeline li.timeline-item .timeline-item-content .timeline-item-date {
  color: #b0b0b0;
  display: block;
  font-size: 12px;
  padding: 4px 0 0;
}

ul.timeline li.timeline-item .timeline-item-content span.request-status {
  top: 0;
}

table.details-table {
  margin:0 0 16px;
  width:  100%;
}
  
table.details-table td {
  border-bottom: 1px solid #eee;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 8px 8px 0;
  vertical-align: top;
}

table.details-table.new td {
  border-bottom: 0px;
  padding: 8px 16px 8px 0;
  width: calc(100% / 3)
}

table.details-table.new tr td:nth-child(3) {padding-right: 0}

table.details-table.new td label{
  color: #17467F;
  display: block;
  font-weight: bold;
}
  
table.details-table td.label {
  font-weight: bold;
  padding-right: 16px;
  width: 185px;
  white-space: nowrap;
}

.status-filters {
  float: right;
  margin-top: -5px;
}

.status-filters i {
  font-size: 16px;
  height: 16px;
  line-height: 30px;
  width: 16px;
}

.quote-comp-modal .ant-modal-body {
  padding-top: 0px;
}

.no-results {
  border: 1px solid #ddd;
  border-radius: 3px;
  clear: both;
  color: #474747;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;  
  padding: 16px;
}

.loading.padded {
  padding: 8px 0 0;
}

.loading span.msg {
  color: #474747;
  display: inline-block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;  
  padding: 0 0 0 12px;
  vertical-align: top;
}

.mat-component-open,
.add-quotation-open,
.mat-rfq-open,
.mat-rfq-items-open,
.compare-open,
.add-specs-modal-trigger,
.mat-quotation-items-open {
  float: right;
}

.mat-component-open.no-comps,
.mat-rfq-open.no-rfq,
.mat-rfq-items-open.no-frq-items,
.mat-quotation-items-open.no-items,
.add-quotation-open.no-quotations,
.mat-quotation-items-open.no-quote-items {
  margin-top: -4px;
}

.add-specs-modal-trigger {
  margin: 0 8px;
}

.mass-assign-comps {
  border: 1px solid #d9d9d9;
  padding: 0 10px 10px;
}

.mass-assign-comps p {
  background: #f5f5f5;
  border-bottom: 1px solid #d9d9d9;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing:  0.28px;  
  margin: 0 -10px 10px;
  padding: 10px;
}

.rfq-mat-comp-options {
  margin-bottom: 16px !important;
}

.rfq-mat-comp-option {
  margin-bottom: 8px;
}

.rfq-mat-comp-options .rfq-mat-comp-option:last-child {
  margin: 0
}

.rfq-mat-comp-option button {
  width: 100%
}

.rfq-mat-comp-option button span {
  display:inline-block;
  vertical-align: top;
}

.rfq-mat-comp-option button strong {
  padding-left: 5px;
  display: inline-block;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rfq-item-form-collapse {
  margin-bottom: 16px !important;
}

.rfq-items-form .rfq-item-form-collapse:last-child {
  margin-bottom: 0 !important;
}

.faux-tab {
  margin-right: 16px;
  margin-bottom: 16px
}

.mat-req-details-rfq-items {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 16px;
}

.mat-req-details-rfq-items h6 {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.mat-req-details-rfq-items h6 span {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin: 0 0 6px;
  padding: 0 0 6px
}

.mat-req-details-rfq-item {
  align-items: flex-start;
  border-top: 1px solid #ddd;
  display: flex;
  font-size: 13px;
}

.mat-req-details-rfq-item.header {
  border: 0px;
  font-weight: bold; 
}

.mat-req-details-rfq-item span.cell {
  margin-right: 10px;
  padding: 6px 0;
}

.mat-req-details-rfq-item span.cell.one {
  flex-grow: 1;
}

.mat-req-details-rfq-item span.cell.two {
  width: 150px;
}

.mat-req-details-rfq-item span.cell.three {
  width: 200px;
}

.mat-req-details-rfq-item span.cell.four {
  width: 150px;
}

.mat-req-details-rfq-item span.cell.five{
  width: 150px;
}

.mat-req-details-rfq-item span.cell.six {
  width: 300px;
}

.mat-req-details-rfq-item span.cell.six.qi {
  width: 150px;
}

.mat-req-details-rfq-item span.cell.seven {
  margin-right: 0;
  width: 70px;
}

.mat-req-details-rfq-item span.cell.seven.qi {
  width: 150px;
}

.edit-rfq-item-trigger {
  cursor: pointer;
}

.overflow-mass {
  margin: auto;
  overflow: auto;  
  position: relative;
  width: 100%;
  z-index: 1;
}

.overflow-mass table {
  margin: auto;
  min-width: 1280px;
  width: 100%;
}

.overflow-mass table td.num {
  color: #d8d8d8;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 32px;
}

.mat-item-option span {
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  padding-right: 8px;
  text-transform: uppercase
}

.mat-item-option span.name {
  color: #000;
  display: block;
  font-size: 14px;
  padding: 0;
  text-transform: initial;
}

.mat-item-option span.new-line {
  display:block;
}

.mat-item-option span em {
  color: #999;
  padding-right: 5px;
  font-weight: 400;
}

.details-general {
  column-gap: 8px;
  display: flex;
  flex-wrap: nowrap;
  margin: 16px 16px 8px;
  vertical-align: start;
}

.details-general .card {
  margin: 0;
  padding-bottom: 8px;
}

.details-general .card  h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.details-general .details-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 8px;
  max-width: calc(100% - 285px);
}

.details-general .details-left.full {
  max-width:  100%;
}

.details-general .details-left .general {
  flex-grow: 1;
}

.details-general .details-right {
  min-width: 285px;
  width: 285px;
}

.date-align {
  display: flex;
  gap: 24px;
}

.request-meta {
  font-size: 14px;
  font-weight: 700;
}

.request-meta span {
  display: inline-block;
  margin: 0 10px 0 0;
}

hr {
  background: #ddd;
  border: 0;
  height: 1px !important;
  margin: 16px -16px;
}

.request-id em.version {
  border-radius: 3px;
  background: #1890ff;
  color:#fff;
  font-size: 10px;
  font-weight: bold;
  padding: 4px 5px 3px;
  margin: 0 0 0 10px; 
  position: relative;
  top:-2px
}

.request-id em.version.flat {
  margin-left: 0;
}

.file-types-list {
  margin: 0;
}

.file-type {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: inline-block;
  margin: 2px 5px 5px 0;
  padding: 2px 5px; 
}

.file-type em {
  color: #D32F2F;
  display: inline-block;
  padding-right: 5px;
}

td.versions a{
  border: 1px solid #1890ff;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  margin-right: 5px;
}

td.versions a:hover {
  background: #1890ff;
  color: #fff;
}

li.component {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: block;
  margin: 3px 5px 5px 0;
  padding: 7px; 
}

li.component span {
  color: #999;
  display:block;
  padding: 3px;
}

li.component span.category {
  background: #f5f5f5;
  border-bottom: 1px solid #d9d9d9;
  border-bottom-right-radius: 3px;
  border-right: 1px solid #d9d9d9;  
  border-top-left-radius: 3px;
  color:#000;
  display:inline-block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.75px;
  margin: 0 0 0 -7px;
  padding: 5px 10px;
  position: relative;
  text-transform: uppercase;
  top: -7px;
}

li.component span.description {
  color: #474747;
}

.quote-comps {
  margin: 0;
  text-align:center;
}

.quote-comps li {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: inline-block;
  margin: 5px 0;
  padding: 0;
}

.quote-comps li table.quote-comp {
  margin: 0;
  padding: 0;
}

.quote-comps li table.quote-comp td {
  border-bottom: 1px solid #eee;
  padding: 0 15px; 
  text-align: left;
  vertical-align: top;
  width: 290px;
}

.quote-comps li table.quote-comp tr:last-child td {
  border-bottom: 0;
}

.quote-comps li table.quote-comp td.select {
  padding: 15px;
  text-align: center;
}

.quote-comps li table.quote-comp td.blank {
  background:#fafafa;
}

.quote-comps li table.quote-comp td + td {
  border-left: 1px solid #eee;
}

.quote-comps li table.quote-comp td h5 {
  font-size: 14px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
}

.quote-comps li table.quote-comp td label {
  color:#474747;
  display: block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.75px;
  margin: 10px 0 0;
  padding: 0;
  text-transform: uppercase;
}

.quote-comps li table.quote-comp td p {
  color:#000;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 0 0 10px;
}

.file-type-list::after {
  content: ', ';
}

.file-type-list:last-child::after {
  content: '';
}

.approval-flow {
  overflow:auto;
  padding: 8px 0 16px;
}

.assignment-box {
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  float: left;
  height: 105px;
  padding: 8px;
  position: relative;
  width: 250px;
}

.assignment-box.Yes {
  background: #fff;
  border-color: #389e0d;
}

.assignment-box.No {
  background: #fff;
  border-color: #cf1322;
}

.assignment-spacer {
  background:#e1e1e1;
  float: left;
  height: 1px;
  margin-top: 51px;
  width: 125px;
}

.assignment-spacer.Yes {
  background: #389e0d;
}

.assignment-spacer.No {
  background: #cf1322;
}

.assignment-box span.to-be,
.assignment-box span.person {
  display: block;
  margin-left: 30px
}

.assignment-box span.assignment-date {
  color: #b0b0b0;
  display: block;
  font-size: 12px;
  margin-left: 30px
}

.assignment-box span.round {
  border: 5px solid #e1e1e1;
  border-radius: 50%;
  height: 20px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10px;
  width: 20px;
}

.assignment-box .anticon  {
  font-size: 20px;
  position: absolute;
  top: 10px;
}

.assignment-box button {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.approval-flow .assignment-spacer:last-of-type {display:none;}

.dnd-item {
  background: #fff;
}

.dnd-item.current td {
  background: #f5f5f5;
}

.dnd-item.current td span {display:none;}

.fifty-split {
  display:flex;
  flex-wrap: wrap;
  column-gap: 16px;
}

.fifty-split > div {
  width: calc(50% - 16px);
}

.fifty-split > div:nth-child(2n) {
  width: 50%;
}

.card h5.qi-expand-header {
  font-size: 14px;
  margin:0;
}

.qi-specs {
  display:flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

.qi-specs > div {
  width: 25%;
}

.qi-specs label{
  color: #17467F;
  display: block;
  font-weight: bold; 
}

.login-header {
  margin: 72px auto 20px;
  text-align: center;
  width: 575px;
}

.login-header h1 {
  color: #000;
  display: inline-block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 32px;
}

.login-header img {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  vertical-align: top;
  width: 32px;
}

.login-form {
  margin: 0 auto;
  width: 368px;
}

.quoteitem-spec-file-header {
  border-bottom: 1px solid #eee;
  display: block;
  font-weight: 500;
  margin: 0 0 10px;
  padding: 0 0 10px;
}

.qi-file-download {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #000;
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 5px 16px;
}

.qi-file-download:hover {
  border-color: #1890ff;
  color: #1890ff;
}

.qi-file-download:active {
  border-color: transparent;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  color: #474747;
}

.targeted-icon  {
  color: #777;
  cursor: default;
  font-size: 18px;
  height: 18px;
  margin: 0 6px 0 0;
  position: relative;
  top: 4px;
  width: 18px; 
}
