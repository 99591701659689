.icon-block {
    border: 1px solid #eee;
    border-radius: 4px;
    color: #000;
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 10px;
    text-align: center;
    vertical-align: top;
    width:160px
}

.icon-block:hover {
    border-color: #bbb;
    color: #000;
    box-shadow: 0 1px 2px rgba(0,0,0,0.25)
}

.icon-block:active {
    border-color: #fff;
    box-shadow: inset 0 0 3px rgba(0,0,0,0.25);
}

.icon-block i {
    font-size: 40px;
    height:40px;
    width:40px;
}

.icon-block span {
    display: block;
}

.pointer {
    display: inline-block;
    height:90px;
    position: relative;
    margin: 0 10px 10px 0;
    width:100px;
}

.pointer span.line {
  background:#474747;
  display:block;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: calc(100% - 5px);
  z-index: 1;
}

.pointer span.triangle-right {
    display: block;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #474747;
    border-top: 5px solid transparent;
    height: 0;    
    width: 0;
    position: absolute;
    top: calc(50%  - 5px);
    right: 0;
}

.pointer span.triangle-left {
    display: block;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #474747;
    border-top: 5px solid transparent;
    height: 0;    
    width: 0;
    position: absolute;
    top: calc(50%  - 5px);
    left: 0;
}
