header {
  background: #003673;
  box-sizing: border-box;
  height: 64px;
  padding: 16px;
  position: relative;
  z-index: 10;
}

header h1 {
  color: #fff;
  display: inline-block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 32px;
  position: relative;
  vertical-align: top;
}

header img {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  vertical-align: top;
  width: 32px;
}

header .links {
  position: absolute;
  right: 0;
  top: 0;
}

header .links button {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.75px;
  margin: 0;
  padding: 21px 16px 15px;  
  text-decoration: none;
  vertical-align: top;
}

header .links button:hover {
  background: rgba(255,255,255,0.1);  
}

header .links button span {
  display: inline-block;
  line-height: 24px;
  padding-left: 8px;
  vertical-align: top;
}

header .links button.ant-dropdown-trigger > .exp {
  margin-left: 4px;
  -webkit-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
  transform-origin: center;
  vertical-align: top !important;
}

header .links button.ant-dropdown-trigger.ant-dropdown-open > .exp {
  -webkit-transform: rotate(180deg);
  transform:rotate(180deg);
}
