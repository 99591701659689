.request-meta {
  font-size: 14px;
  font-weight: 700;
}

.request-meta span {
  display: inline-block;
  margin: 0 10px 0 0;
}

hr {
  background: #ddd;
  border: 0;
  height: 1px !important;
  margin: 16px -16px;
}

ul.quote-items {
  margin: 0;
}

.quote-items li {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 0;
}

.quote-items li table.quote-comp {
  margin: 0;
  padding: 0;
}

.quote-items li table.quote-comp td {
  border-bottom: 1px solid #eee;
  padding: 0 15px; 
  vertical-align: top;
  width: 290px;
}

.quote-items li table.quote-comp tr:last-child td {
  border-bottom: 0;
}

.quote-items li table.quote-comp td.blank {
  background:#fafafa;
}

.quote-items li table.quote-comp td.select {
  padding: 15px;
  text-align: center;
}

.quote-items li table.quote-comp td + td {
  border-left: 1px solid #eee;
}

.quote-items li table.quote-comp td h5 {
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
}

.quote-items li table.quote-comp td label {
  color:#474747;
  display: block;
  font-family: 'Google Sans', Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.75px;
  margin: 10px 0 0;
  padding: 0;
  text-transform: uppercase;
}

.quote-items li table.quote-comp td p {
  color:#000;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 0 0 10px;
}
