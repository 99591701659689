.request-meta {
  font-size: 14px;
  font-weight: 700;
}

.request-meta span {
  display: inline-block;
  margin: 0 10px 0 0;
}

hr {
  background: #ddd;
  border: 0;
  height: 1px !important;
  margin: 16px -16px;
}
